import { ICreateUserLog } from "@goono-commons/api/request/user_log";
import { createUserLog } from "@goono-react-commons/services/user_log";
import { useLoginUserInfo } from "@utils/hooks/service/useLoginUserInfo";
import { useMutation } from "react-query";

export const useCreateUserLogMutation = () => {
  const userInfo = useLoginUserInfo();

  return useMutation(
    async (props: ICreateUserLog) => await createUserLog(userInfo.token, props)
  );
};
