import {
  ProjectDescription,
  ProjectDescriptionSchema,
  ProjectOptions,
  ProjectOptionsSchema,
} from "../../v3/project";
import T from "@redwit-commons/utils/typecheck";
import { AccessTime, addAccessTime } from "./access_time";
import { IpfsDataSchema, IpfsData } from "./ipfs";

export enum UserLogType {
  ORIGIN_DOWNLOAD_FILE = "ORIGIN_DOWNLOAD_FILE",
  UPLOAD_NOTE = "UPLOAD_NOTE",
  UPLOAD_CHUNKS_NOTES = "UPLOAD_CHUNKS_NOTES",
  UPLOAD_GD_NOTE = "UPLOAD_GD_NOTE",
  USE_TICKET = "USE_TICKET",
  LINK_GITHUB = "LINK_GITHUB",
  VISIT = "VISIT",
  ACCESS = "ACCESS",
  ACCESS_ADMIN = "ACCESS_ADMIN",
  ACCESS_USER = "ACCESS_USER",
  ACCESS_MANAGER = "ACCESS_MANAGER",
  DOWNLOAD_FILE = "DOWNLOAD_FILE",
  VIEW_CONTENT = "VIEW_CONTENT",
  DELETE = "DELETE",
  CREATE_FOLDER = "CREATE_FOLDER",
  DELETE_FOLDER = "DELETE_FOLDER",
  UPDATE_FOLDER = "UPDATE_FOLDER",
  UPLOAD_GITHUB_FILE = "UPLOAD_GITHUB_FILE",
  UPDATE_PROJECT_INFO = "UPDATE_PROJECT_INFO",
  UPDATE_PROJECT_OPTION = "UPDATE_PROJECT_OPTION",
  CREATE_PROJECT = "CREATE_PROJECT",
  DELETE_PROJECT = "DELETE_PROJECT",
  TRANSFER_PROJECT_OWNER = "TRANSFER_PROJECT_OWNER",
  UPDATE_FILE_PATH = "UPDATE_FILE_PATH",
  INVITE_RESEARCHER = "INVITE_RESEARCHER",
  DELETE_RESEARCHER = "DELETE_RESEARCHER",
  CREATE_TRIAL_PLAN = "CREATE_TRIAL_PLAN",
  ASSIGN_TRIAL_PLAN = "ASSIGN_TRIAL_PLAN",
  UPDATE_NOTE = "UPDATE_NOTE",
  CREATE_COMMENT = "CREATE_COMMENT",
  /**
   * 에러 객체를 error_object 에 저장합니다.
   * 에러 위치 정보를 error_location 에 저장합니다.
   */
  EXCEPTION_CLIENT = "EXCEPTION_CLIENT",
}

export enum GOONOAdminLogType {
  // FOR GOONO ADMIN LOG
  ONLINE = "ONLINE",
  /** @deprecated */
  UPDATE_LICENSE = "UPDATE_LICENSE",
  UPDATE_WORKSPACE = "UPDATE_WORKSPACE",
  /** @deprecated */
  CREATE_LICENSE = "CREATE_LICENSE",
  /** @deprecated */
  DELETE_LICENSE = "DELETE_LICENSE",
  /** @deprecated */
  RESIGN_LICENSE = "RESIGN_LICENSE",
  /** @deprecated */
  ASSIGN_LICENSE = "ASSIGN_LICENSE",
  /** @deprecated */
  EXPIRE_LICENSE = "EXPIRE_LICENSE",
  EXPIRE_PLAN = "EXPIRE_PLAN",
  /** @deprecated */
  RENEW_LICENSE = "RENEW_LICENSE",
  RENEW_PLAN = "RENEW_PLAN",
  UPDATE_CONTENTS = "UPDATE_CONTENTS",
  CREATE_CONTENTS = "CREATE_CONTENTS",
  DELETE_CONTENTS = "DELETE_CONTENTS",
  VIEW_USER_INFO = "VIEW_USER_INFO",
  /** @deprecated */
  VIEW_LICENSE = "VIEW_LICENSE",
  VIEW_WORKSPACE = "VIEW_WORKSPACE",
  VIEW_USER_TIME = "VIEW_USER_TIME",
  VIEW_DASHBOARD = "VIEW_DASHBOARD",
  /** @deprecated */
  ASSIGN_USER_LICENSE = "ASSIGN_USER_LICENSE",
  ASSIGN_USER_WORKSPACE = "ASSIGN_USER_WORKSPACE",
  /** @deprecated */
  RESIGN_USER_LICENSE = "RESIGN_USER_LICENSE",
  RESIGN_USER_WORKSPACE = "RESIGN_USER_WORKSPACE",
  ASSING_USER_EMAIL = "ASSING_USER_EMAIL",
  SEND_PUSH = "SEND_PUSH",
  MAKE_TEMP_PURCHASE = "MAKE_TEMP_PURCHASE",
  MAKE_WORKSPACE_PLAN_PURCHASE = "MAKE_WORKSPACE_PLAN_PURCHASE",
  /**
   * 에러 객체를 error_object 에 저장합니다.
   * 에러 위치 정보를 error_location 에 저장합니다.
   */
  EXCEPTION_SERVER = "EXCEPTION_SERVER",
}

export enum ProjectActivityType {
  ORIGIN_DOWNLOAD_FILE = UserLogType.ORIGIN_DOWNLOAD_FILE,
  UPLOAD_CHUNKS_NOTES = UserLogType.UPLOAD_CHUNKS_NOTES,
  UPLOAD_GITHUB_FILE = UserLogType.UPLOAD_GITHUB_FILE,
  DOWNLOAD_FILE = UserLogType.DOWNLOAD_FILE,
  DELETE = UserLogType.DELETE,
  CREATE_FOLDER = UserLogType.CREATE_FOLDER,
  DELETE_FOLDER = UserLogType.DELETE_FOLDER,
  UPDATE_FOLDER = UserLogType.UPDATE_FOLDER,
  UPDATE_PROJECT_INFO = UserLogType.UPDATE_PROJECT_INFO,
  UPDATE_PROJECT_OPTION = UserLogType.UPDATE_PROJECT_OPTION,
  CREATE_PROJECT = UserLogType.CREATE_PROJECT,
  DELETE_PROJECT = UserLogType.DELETE_PROJECT,
  TRANSFER_PROJECT_OWNER = UserLogType.TRANSFER_PROJECT_OWNER,
  UPDATE_FILE_PATH = UserLogType.UPDATE_FILE_PATH,
  INVITE_RESEARCHER = UserLogType.INVITE_RESEARCHER,
  DELETE_RESEARCHER = UserLogType.DELETE_RESEARCHER,
  UPDATE_NOTE = UserLogType.UPDATE_NOTE,
  CREATE_COMMENT = UserLogType.CREATE_COMMENT,
}

export const LogTypeList = [
  ...Object.values(UserLogType),
  ...Object.values(GOONOAdminLogType),
];
export const ProjectHomeActivityList = [
  ProjectActivityType.CREATE_PROJECT,
  ProjectActivityType.TRANSFER_PROJECT_OWNER,
  ProjectActivityType.DOWNLOAD_FILE,
  ProjectActivityType.ORIGIN_DOWNLOAD_FILE,
  ProjectActivityType.INVITE_RESEARCHER,
  ProjectActivityType.UPDATE_PROJECT_INFO,
  ProjectActivityType.DELETE_RESEARCHER,
  ProjectActivityType.UPDATE_PROJECT_OPTION,
  ProjectActivityType.DELETE_PROJECT,
];
export const ProjectDetailActivityList = [
  ...Object.values(ProjectActivityType),
].filter((type) => {
  return type !== ProjectActivityType.DELETE_PROJECT;
});
export const AdminProjectActivityList = [...Object.values(ProjectActivityType)];

export enum TrialPlanActivityType {
  CREATE_TRIAL_PLAN = UserLogType.CREATE_TRIAL_PLAN,
  ASSIGN_TRIAL_PLAN = UserLogType.ASSIGN_TRIAL_PLAN,
  /**@deprecated */
  CREATE_PROJECT = UserLogType.CREATE_PROJECT,
}

export type UserLogObjectCore = {
  readonly type:
    | UserLogType
    | GOONOAdminLogType
    | ProjectActivityType
    | TrialPlanActivityType
    | AdminActivityType;
  readonly UserId?: string;
  readonly NoteId?: string;
  readonly ProjectId?: string;
  readonly LicenseId?: string;
  readonly WorkspaceId?: string;
  readonly ip_addr?: string;
  readonly location?: string;
  readonly query?: string;
  readonly body?: string;
  readonly url?: string;
  readonly uploadType?: "gallery" | "camera" | "file";
  readonly device?: string;
  readonly platformOS?: string;
  readonly app_version?: string;
  readonly chunk_notes?: number;
  readonly error_object?: string;
  readonly error_location?: string;
  readonly uuid?: string; // 에러 재전송 시 겹치는거 방지하기 위함
  readonly FolderId?: string;
  readonly project_activity?: string;

  // admin 로그에 들어가기 됨
  readonly userType?: "goono-admin" | "user";
  readonly adminEmail?: string;
  readonly institute?: string;
  readonly target_email?: string;
  readonly license_role?: string;
  readonly content_title?: string;
  readonly content_id?: string;
  readonly push_title?: string;
  readonly push_info?: string;
  readonly paid_token?: string;
  readonly paid_info?: string;
};

// id 는 자동 생성
export type UserLogObject = AccessTime &
  UserLogObjectCore & { readonly id: number };

export const UserLogObjectCoreSchema = T.object()
  .addField("UserId", T.string(), false)
  .addField("NoteId", T.string(), false)
  .addField("ProjectId", T.string(), false)
  .addField("LicenseId", T.string(), false)
  .addField("WorkspaceId", T.string(), false)
  .addField("ip_addr", T.string(), false)
  .addField("location", T.string(), false)
  .addField("query", T.string(), false)
  .addField("body", T.string(), false)
  .addField("url", T.string(), false)
  .addField("device", T.string(), false)
  .addField("uploadType", T.string(), false)
  .addField("app_version", T.string(), false)
  .addField("platformOS", T.string(), false)
  .addField("chunk_notes", T.number(), false)
  .addField("error_object", T.string(), false)
  .addField("error_location", T.string(), false)
  .addField("uuid", T.string(), false)
  .addField("FolderId", T.string(), false)
  .addField("project_activity", T.string(), false)
  .addField("userType", T.string(), false)
  .addField("adminEmail", T.string(), false)
  .addField("institute", T.string(), false)
  .addField("target_email", T.string(), false)
  .addField("license_role", T.string(), false)
  .addField("content_title", T.string(), false)
  .addField("content_id", T.string(), false)
  .addField("push_title", T.string(), false)
  .addField("push_info", T.string(), false)
  .addField("paid_token", T.string(), false)
  .addField("paid_info", T.string(), false)
  .addField("type", T.string().withEnum(LogTypeList));

export const UserLogObjectSchema = addAccessTime(
  UserLogObjectCoreSchema.clone().addField("id", T.string())
);

export const validateUserLogObjectCore = T.mkValidator<UserLogObjectCore>(
  UserLogObjectCoreSchema
);
export const extractUserLogObject =
  T.mkObjectExtractor<UserLogObject>(UserLogObjectSchema);

type ActivityUserInfo = {
  profile: IpfsData;
  name: string;
};
type GitRepoInfo = {
  id: string;
  name: string;
};
type UpdateFolderInfo = {
  prev_name: string;
  new_name: string;
};
type UpdateProjectInfo = {
  prev_info: {
    name?: string;
    description?: ProjectDescription;
  };
  new_info: {
    name?: string;
    description?: ProjectDescription;
  };
};
type UpdateProjectOption = {
  prev_opt: ProjectOptions;
  new_opt: ProjectOptions;
};
type UpdateFilePath = {
  prev_path: {
    project_name: string;
    folder_name?: string;
  };
  new_path: {
    project_id: string;
    project_name: string;
    folder_id?: string;
    folder_name?: string;
  };
};
type UpdateNoteInfo = {
  prev_name: string;
  new_name: string;
};
type CommentInfo = {
  pageNum: number | undefined;
  fileName: string;
};
export type ProjectActivityMeta = {
  creator: ActivityUserInfo;
  project_name: string;
  chunk_files?: number;
  folder_name?: string;
  git_repo?: GitRepoInfo;
  update_folder?: UpdateFolderInfo;
  update_project_info?: UpdateProjectInfo;
  update_project_option?: UpdateProjectOption;
  update_project_owner?: string;
  update_file_path?: UpdateFilePath;
  update_project_member?: string;
  update_note?: UpdateNoteInfo;
  comment?: CommentInfo;
  output_file?: IpfsData;
};
const ActivityUserInfoSchema = T.object()
  .addField("profile", IpfsDataSchema.clone())
  .addField("name", T.string());
const GitRepoInfoSchema = T.object()
  .addField("id", T.string())
  .addField("name", T.string());
const UpdateFolderInfoSchema = T.object()
  .addField("prev_name", T.string())
  .addField("new_name", T.string());
const UpdateProjectInfoSchema = T.object()
  .addField(
    "prev_info",
    T.object()
      .addField("name", T.string(), false)
      .addField("description", ProjectDescriptionSchema.clone(), false)
  )
  .addField(
    "new_info",
    T.object()
      .addField("name", T.string(), false)
      .addField("description", ProjectDescriptionSchema.clone(), false)
  );
const UpdateProjectOptionSchema = T.object()
  .addField("prev_opt", ProjectOptionsSchema.clone())
  .addField("new_opt", ProjectOptionsSchema.clone());
const UpdateFilePathSchema = T.object()
  .addField(
    "prev_path",
    T.object()
      .addField("project_name", T.string())
      .addField("folder_name", T.string(), false)
  )
  .addField(
    "new_path",
    T.object()
      .addField("project_id", T.string())
      .addField("project_name", T.string())
      .addField("folder_id", T.string(), false)
      .addField("folder_name", T.string(), false)
  );
const UpdateNoteInfoSchema = UpdateFolderInfoSchema.clone();
const CommentInfoSchema = T.object()
  .addField("pageNum", T.number(), false)
  .addField("fileName", T.string());

const addProjectActivityMeta = (from: T): T => {
  return from
    .addField("creator", ActivityUserInfoSchema.clone())
    .addField("project_name", T.string())
    .addField("chunk_files", T.number(), false)
    .addField("folder_name", T.string(), false)
    .addField("git_repo", GitRepoInfoSchema.clone(), false)
    .addField("update_folder", UpdateFolderInfoSchema.clone(), false)
    .addField("update_project_info", UpdateProjectInfoSchema.clone(), false)
    .addField("update_project_option", UpdateProjectOptionSchema.clone(), false)
    .addField("update_project_owner", T.string(), false)
    .addField("update_file_path", UpdateFilePathSchema.clone(), false)
    .addField("update_project_member", T.string(), false)
    .addField("update_note", UpdateNoteInfoSchema.clone(), false)
    .addField("comment", CommentInfoSchema.clone(), false)
    .addField("output_file", IpfsDataSchema.clone(), false);
};
export const ProjectActivityMetaSchema = addProjectActivityMeta(T.object());
export const validteProjectActivityMeta = T.mkValidator<ProjectActivityMeta>(
  ProjectActivityMetaSchema
);

export const serializeProjectActivityMeta = (data: ProjectActivityMeta) => {
  return JSON.stringify(data);
};
export const deSerializeProjectActivityMeta = (etc: string) => {
  return validteProjectActivityMeta(JSON.parse(etc));
};

export const validateActivityUserInfo = T.mkValidator<ActivityUserInfo>(
  ActivityUserInfoSchema
);
export const validateGitRepoInfo =
  T.mkValidator<GitRepoInfo>(GitRepoInfoSchema);
export const validateUpdateFolderInfo = T.mkValidator<UpdateFolderInfo>(
  UpdateFolderInfoSchema
);
export const validateUpdateProjectInfo = T.mkValidator<UpdateProjectInfo>(
  UpdateProjectInfoSchema
);
export const validateUpdateProjectOption = T.mkValidator<UpdateProjectOption>(
  UpdateProjectOptionSchema
);
export const validateUpdateFilePath =
  T.mkValidator<UpdateFilePath>(UpdateFilePathSchema);
export const validateUpdateNoteInfo =
  T.mkValidator<UpdateNoteInfo>(UpdateNoteInfoSchema);
export const validateCommentInfo =
  T.mkValidator<CommentInfo>(CommentInfoSchema);

export enum AdminActivityType {
  START_TRIAL = UserLogType.CREATE_TRIAL_PLAN,
  /** @deprecated */
  EXPIRE_LICENSE = GOONOAdminLogType.EXPIRE_LICENSE,
  EXPIRE_PLAN = GOONOAdminLogType.EXPIRE_PLAN,
  /** @deprecated */
  UPDATE_LICENSE = GOONOAdminLogType.UPDATE_LICENSE,
  RENEW_PLAN = GOONOAdminLogType.RENEW_PLAN,
  /** @deprecated */
  RENEW_LICENSE = GOONOAdminLogType.RENEW_LICENSE,
  UPDATE_WORKSPACE = GOONOAdminLogType.UPDATE_WORKSPACE,
}
export const GOONOAdminActivityList = [...Object.values(AdminActivityType)];

type LicenseDescription = {
  period?: {
    startAt?: string;
    endAt?: string;
  };
  seats?: number;
  memo?: string;
};

type UpdateLicenseInfo = {
  prev_info: LicenseDescription;
  new_info: LicenseDescription;
};

export type AdminActivityMeta = {
  creator_name?: string;
  license_name?: string;
  update_license?: UpdateLicenseInfo;
  institution_name?: string;
};

const LicenseDescriptionSchema = T.object()
  .addField(
    "period",
    T.object()
      .addField("startAt", T.string(), false)
      .addField("endAt", T.string(), false),
    false
  )
  .addField("seats", T.number(), false)
  .addField("memo", T.string(), false)
  .addField("rank", T.string(), false);

const UpdateLicenseInfoSchema = T.object()
  .addField("prev_info", LicenseDescriptionSchema.clone(), false)
  .addField("new_info", LicenseDescriptionSchema.clone(), false);

const addAdminActivityMeta = (from: T): T => {
  return from
    .addField("creator_name", T.string(), false)
    .addField("license_name", T.string(), false)
    .addField("institution_name", T.string(), false)
    .addField("update_license", UpdateLicenseInfoSchema.clone(), false);
};
export const AdminActivityMetaSchema = addAdminActivityMeta(T.object());
export const validteAdminActivityMeta = T.mkValidator<AdminActivityMeta>(
  AdminActivityMetaSchema
);
export const validateUpdateLicenseInfo = T.mkValidator<UpdateLicenseInfo>(
  UpdateLicenseInfoSchema
);

export const serializeAdminActivityMeta = (data: AdminActivityMeta) => {
  return JSON.stringify(data);
};
export const deSerializeAdminActivityMeta = (etc: string) => {
  return validteAdminActivityMeta(JSON.parse(etc));
};
