import { useDispatch } from "react-redux";
import {
  doTokenActionAsync,
  TokenActionKind,
} from "src/redux/store/reducers/token";
import ScreenURL from "src/routes/route_list";

export const useHandleLogout = () => {
  const dispatch = useDispatch();

  return {
    logout: async () => {
      await doTokenActionAsync(dispatch, {
        kind: TokenActionKind.TRY_INIT,
      });

      window.location.replace(ScreenURL.LOGIN);
    },
  };
};
